/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Helmet from 'react-helmet';
import staticData from './index-data.json';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Section from '../components/Section';
import Calculator from '../components/Calculator';
import CalculatorTabs from '../components/CalculatorTabs';
import Writers from '../components/Writers';
import Banner from '../components/Banner';
import HowItWorks from '../components/HowItWorks';
import Testimonials from '../components/Testimonials';
import Faq from '../components/Faq';

class BuyEssayOnlinePage extends React.Component {
  componentDidMount() {

  }

  render() {
    const awSiteUrl = 'https://www.advancedwriters.com';

    return (
      <Layout className="page-wrapper">
        <Helmet>
          <title>Write My Essay in Canada: A Trustworthy Service >> AdvancedWriters.com</title>
          <meta
            name="description"
            content="“Write my paper!” is a perfectly natural request that online services often receive from desperate students. It’s not easy to create a perfect essay, but our services assist learners throughout Canada by providing reliable examples to follow. Don’t hesitate to get help today, and you’ll see that it’s easier to study with our guidance."
          />
          <link rel="canonical" href="https://ca.advancedwriters.com/write-my-essay.html" />
        </Helmet>

        <Header />

        <Section className="section-hero">
          <div className="section-hero__content">
            <h1 className="section__title">Write my essay perfectly in Canada!</h1>
            <p className="section__subtitle">Our service answers the call to “write my essay!” in Canada and beyond, helping students become better writers.</p>
            <div className="section__buttons">
              <a href={`${awSiteUrl}/dashboard/orders/create/`} rel="nofollow" className="btn btn--green-fill btn--with-arrow">Order now</a>
              <a href={`${awSiteUrl}/dashboard/inquiry/`} rel="nofollow" className="link--white">Get a free quote</a>
            </div>
          </div>
        </Section>

        <LazyLoadComponent>
          <Testimonials
            title="What our customers say"
            subtitle="We aim to provide a paper writing service of the highest quality, and that’s why our website has a 4.83 rating on Sitejabber, an independent review platform. Check out some testimonials from our customers before you place your order."
            testimonials={staticData.testimonials}
          />
        </LazyLoadComponent>

        <LazyLoadComponent>
          <Section className="section-benefits">
            <h2 className="section__title">Benefits we have for you</h2>
            <ul className="section-benefits__list list--clean columns columns--col-3">
              <li className="section-benefits__item ico__support--before">
                <div className="section-benefits__item-title">24/7 customer service</div>
                <div className="section-benefits__item-text">Ask our support specialists any questions, and they will help you.</div>
              </li>
              <li className="section-benefits__item ico__timer--before">
                <div className="section-benefits__item-title">Fast assistance</div>
                <div className="section-benefits__item-text">We can deal with your small tasks in 4+ hours.</div>
              </li>
              <li className="section-benefits__item ico__pig--before">
                <div className="section-benefits__item-title">Affordable prices</div>
                <div className="section-benefits__item-text">You can get cheaper prices if you place your order in advance.</div>
              </li>
              <li className="section-benefits__item ico__confidentiality--before">
                <div className="section-benefits__item-title">Confidentiality</div>
                <div className="section-benefits__item-text">We don’t publicly disclose any of our customers' data.</div>
              </li>
              <li className="section-benefits__item ico__note-and-pen--before">
                <div className="section-benefits__item-title">Free formatting</div>
                <div className="section-benefits__item-text">We format your order for free so that it matches your instructions.</div>
              </li>
              <li className="section-benefits__item ico__lists--before">
                <div className="section-benefits__item-title">75+ disciplines</div>
                <div className="section-benefits__item-text">Our experts can cover multidisciplinary topics too!</div>
              </li>
            </ul>
          </Section>
        </LazyLoadComponent>

        <LazyLoadComponent>
          <Writers
            title="Get writing help from professionals"
            subtitle="Currently, our essay writing company has a team of 476 experts. They all specialize in different disciplines and come from diverse backgrounds, which allows us to complete an incredible variety of orders.
            "
            writers={staticData.writers}
          />
        </LazyLoadComponent>

        <HowItWorks
          customClass="section--bg-gray"
          title="How to buy"
          subtitle=""
          steps={staticData.howitworks}
        />

        <Banner
          className="section-banner-black"
          title="Ask an expert to write an essay in no time"
          buttonTitle="Place an order"
        />

        <Section className="section-seo-page section--bg-gray">
          <div className="section-seo-page-block content--thin-800">
            <h2 className="section-seo-page__title">Write my essay for me: Can a reliable expert do it?</h2>
            <p>Sometimes, it might seem impossible to find an advanced specialist ready to answer the call to “write essay for me!” in Canada. Nevertheless, the internet offers new possibilities to all learners, and AdvancedWriters employs 500+ trustworthy specialists to meet your academic needs. These points will familiarize you with our services.</p>
            <ul className="list--clean list--check-green">
              <li><strong>We’ve been helping students write essays since 2005.</strong> Our flexibility enables us to support Canadian learners at any convenient time. Years on the market have taught us to be responsive and professional in any situation, achieving the necessary results no matter what happens.</li>
              <li><strong>Our 66 support operators work 24/7 to assist you.</strong> If you come to us asking “do my essay paper” but have trouble navigating the site or need immediate help, these qualified workers will get you through whatever difficulties you may be having.</li>
              <li><strong>We deliver 97.98% of orders on time.</strong> Some of them even arrive before the deadline. We’re aware that this “score” isn’t perfect, and try to minimize all technical errors to improve the service’s quality.</li>
              <li><strong>The average rating our customers give us is 8.5/10.</strong> College and university students who order from us usually leave satisfied. We still try our hardest to pleasantly surprise each customer, but the current figures speak for themselves.</li>
              <li><strong>Our shortest deadline is just 4 hours.</strong> If you need assistance immediately, it’s possible to get it without having to wait several days. However, ordering in advance and giving your expert more time makes your paper as cheap as it can get.</li>
              <li><strong>Free formatting, title, and reference page.</strong> If you need a good example to craft your essay easily, it goes without saying that all the formal requirements must be followed. That’s why we don’t charge our clients for these basic features.</li>
            </ul>
          </div>
        </Section>

        <Section className="section-seo-page">
          <div className="section-seo-page-block content--thin-800">
            <h2 className="section-seo-page__title">Do my essay in Canada: What extra features do I get?</h2>
            <p>When you ask, “do my essay for me,” you expect top-notch services, and we have developed many supplemental features that can take your essays to a new level. They can make your samples easier to understand, teaching you to use precise data while illustrating your research and cite masterfully. Here are some of these add-ons.</p>

            <ul className="list--clean list--check-green">
              <li>
                <p><strong>Smart paper with explanations.</strong> For an additional 20% of your order price, you’ll get an essay with comments that explain every paragraph, highlighting important or difficult aspects of the paper. You’ll get an advanced understanding of the essential details, ensuring your success.</p>
              </li>
              <li>
                <p><strong>Copy of sources used in the work.</strong> For $14.95, you can get copies of articles the writer used in the essay, so you can analyze what they chose to cite and why. This level of detail is what many people expect when they type “pay someone to write my essay” into their search engine.</p>
              </li>
              <li>
                <p><strong>Charts and slides.</strong> These are priced at $5 each, and such visual aids can make any paper clearer and enhance its message. That’s why many students need such additions.</p>
              </li>
              <li>
                <p><strong>Expert samples.</strong> For just $5, you can get three random pages from previous works of the specialist who will do your essay and see if the quality is good enough. We’re ready to help you with any task, and your trust is our highest priority.</p>
              </li>
            </ul>
          </div>
        </Section>

        <Section className="section-seo-page section--bg-gray">
          <div className="section-seo-page-block content--thin-800">
            <h2 className="section-seo-page__title">Write my paper in Canada: Our guarantees</h2>
            <p>When we answer your call, “write my paper for me!” we care not only about the results, but also about protecting your data and ensuring the utmost integrity. Here are some of the most important guarantees we offer.</p>
            <ul className="list--clean list--check-green">
              <li><strong>100% plagiarism-free papers.</strong> We only provide you with original materials, knowing that you pay for personalized assistance crafted specifically for your case. Our system automatically analyzes all uploaded documents, and only the papers that follow academic integrity standards are presented to our clients.</li>
              <li><strong>The complete confidentiality of the services provided.</strong> Your online security will never suffer with us, since we only use your information to provide you with quality help. That’s why you can trust us with your phone number and email address.</li>
              <li><strong>Free revisions if instructions aren’t followed.</strong> It may sometimes happen that an essay containing no plagiarism is still missing something you asked for. In this case, you can ask for a free revision, and all corrections will be made as soon as possible without additional fees.</li>
              <li><strong>A reliable money-back policy.</strong> We do our best to satisfy the “I need help with my essay” demand, but if we fail to do that, we’ll refund you after the manager reviews the case. You don’t have to worry about losing your money, since we always try to stay fair.</li>
            </ul>
          </div>
        </Section>

        <Section className="section-seo-page">
          <div className="section-seo-page-block content--thin-800">
            <h2 className="section-seo-page__title">Asking experts to do my paper: How it works</h2>
            <p>If you have this question, the answer is right here! For us to fulfill your “do my paper for me” request, you’ll need to place an order on our site. This checklist contains all the key steps, but if you still have questions after reading it, our customer support specialists will gladly help you.</p>
            <ol className="list--clean list--numeric">
              <li><strong>Create an account and fill out the order form.</strong> Specify the deadline, upload the instructions, and write the things you need someone doing your paper to pay special attention to.</li>
              <li><strong>Pay for your essay.</strong> It's entirely safe to transfer money using our site from any location in Canada. We protect every transaction, and work on the order begins as soon as we get the money.</li>
              <li><strong>Monitor the expert’s progress.</strong> After you pay someone to write a paper, you’ll have the possibility to track the order and monitor the writing process. You can communicate with the expert and message them if you forgot about something crucial.</li>
              <li><strong>Receive and approve the completed work.</strong> We'll send the download link to your email after completing the essay you asked for. That way, you'll get an opportunity to assess how professional it is and say if any corrections are needed before accepting the order.</li>
            </ol>
          </div>
        </Section>

        <Section className="section-seo-page section--bg-gray">
          <div className="section-seo-page-block content--thin-800">
            <h2 className="section-seo-page__title">Help me write my essay in Canada as soon as possible</h2>
            <p>People who say, “getting help with my essay shouldn’t be a chore!” are right. After all, it’s nearly impossible to write a perfect paper on the first try, but sometimes, it’s a necessity. That’s why we offer affordable and easy-to-order assistance with every kind of essay. We value your time and trust, making sure to get you personalized academic examples to follow and learn from. Our experts are experienced enough to tackle the most ambitious projects, and you can check their capabilities at any time, since our site works 24/7.</p>
            <p>With our assistance, you can gain the necessary confidence to remove the phrase “I’m not a good writer” from your conversations with teachers and peers. We offer more than just one-time help, since you can use the paper we provide to make your essay skills perfect. So, if your professor doesn't have enough time to work on your struggles and you ask yourself where to find the opportunity to improve your writing, you can safely rely on our services.</p>
          </div>
        </Section>

        <LazyLoadComponent>
          <Section className="section-calculator section-calculator-pricing">
            <div className="section-calculator__calculator">
              <h2 className="section-calculator__heading">Calculate the price of your task</h2>

              <Calculator />
            </div>
            <div className="section-calculator__tabs">
              <CalculatorTabs />
            </div>
          </Section>
        </LazyLoadComponent>
        <Section className="section-seo-page section--bg-gray">
          <div className="section-seo-page-block content--thin-800">
            <h2 className="section-seo-page__title">A few good reasons to use our service</h2>
            <p>You may have many reasons to ask us to write an essay or paper for you. Firstly, you may have some
              troubles in your life, which can impair your analytical thinking and even English writing skills,
              especially if you have a hyper-sensitive type of personality. Secondly, you may encounter all kinds of
              difficulties with your studies. Using our advice, you can do a lot to improve your paragraph structure,
              for example. If you ever wanted someone to tutor you on many essay-related topics, our service is a
              perfect choice. With our help, you will be able to see the mistakes that are common among students and get
              on the path of improving many of your learning patterns. Even the best students learn from others. Why not
              take advantage of the chance to utilize a cheap service aimed at ensuring the high quality of the
              delivered materials?</p>
            <br />
            <h2 className="section-seo-page__title">Ensuring great quality: How we do it</h2>
            <p>Our service is fanatical about ensuring the quality of your papers. Still, if we are faced with badly
              written instructions, providing help without some modifications may be difficult. “Most obviously, I want
              my paper to be great.” Well, in that case, ensure that, above all, you write the instructions clearly.
              Another important feature is the need to avoid various contradictions. For example, if you want us to
              write something about the features of the psychological self, asking for an economic analysis in the same
              paper would be irrational without a good explanation. Also, make sure you include the necessary files
              (textbooks and articles, for example) if there are any. “All that I have to do in order to get you to
              write an essay for me is give the right information?” Definitely. The more robust data you provide, the
              better.</p>
          </div>
        </Section>
        <Faq
          className="section-faq"
          title="Frequently asked questions"
          faq={staticData.faq}
        />

        <Banner
          className="section-banner-green section-banner-green-with-typewriter"
          title="Get fast help with your essay in Canada"
          buttonTitle="Order essay"
        />
      </Layout>
    );
  }
}

export default BuyEssayOnlinePage;
